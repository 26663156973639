import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import circleFit from 'circle-fit'

import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import convertLangLatToUTM from '@tabeeb/modules/shared/utils/gpsCoordinates/convertLangLatToUTM'

import { calculateAzimuth } from '@tabeeb/modules/compass/services'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { getSourcePagesAssets } from '@tabeeb/modules/gallery/actions'

const EMPTY_LIST = []

const useModelSourcePages = ({ enabled }) => {
  const pageId = useSelector(getSelectedGalleryItemId)

  const {
    loading,
    loaded,
    response: sourceAssets,
  } = useApiRequest({
    request: getSourcePagesAssets.request,
    payload: pageId,
    enabled: !!pageId && enabled,
    defaultResponse: EMPTY_LIST,
  })

  const pages = useMemo(() => {
    const { center, distances, radius } = circleFit.compute(
      sourceAssets.map((asset) => {
        return convertLangLatToUTM(asset.Latitude, asset.Longitude)
      })
    )

    return sourceAssets.map((asset, i) => ({
      id: asset.PageId,
      name: asset.PageName,
      image: {
        azimuth: calculateAzimuth(center, asset.Longitude, asset.Latitude),
        altitude: asset.Altitude,
        distance: radius + distances[i],
        url: asset.Url,
        thumbnailUrl: asset.ThumbnailUrl || asset.Url,
      },
    }))
  }, [sourceAssets])

  return useMemo(
    () => ({
      loading,
      loaded,
      pages,
    }),
    [loading, loaded, pages]
  )
}

export default useModelSourcePages
